<template>
  <v-card>
    <v-progress-linear
      :active="loading"
      color="blue"
      height="4"
      indeterminate
    />
    <v-card-title>{{ conversation.managerName }}</v-card-title>
    <v-card-text>
      <span>
        <b>{{ $t('system.was_conversation_with_manager') }}</b>
        <v-radio-group
          class="radio mt-0"
          :value="conversation.conversationWithManager"
          readonly
          dense
          row
        >
          <v-radio
            :value="true"
            color="success"
            :label="$t('system.yes')"
          />
          <v-radio
            :value="false"
            color="error"
            :label="$t('system.no')"
          />
        </v-radio-group>
      </span>

      <InfoRow :text="$t('system.email')" :value="conversation.managerEmail" defaultValue="..." />
      <InfoRow :text="$t('system.phone')" :value="conversation.managerPhone" defaultValue="..." />
      <InfoRow :text="$t('system.hobby')" :value="conversation.managerHobby" defaultValue="..." />
      <InfoRow :text="$t('system.is_married')" :value="conversation.isManagerMarried" defaultValue="..." />
      <v-divider/>
      <h6>{{ $t('system.note') }}:</h6>
      <p><b>{{ conversation.note || '...' }}</b></p>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from 'axios';
import InfoRow from '@/components/Visits/VisitSteps/InfoRow';
import notification from "@/notification/notify";

export default {
  name: 'ConversationStep',
  components: {InfoRow},
  props: {
    visitId: {
      type: [Number, String],
      required: true
    }
  },
  data: () => ({
    loading: false,
    conversation: {
      accountName: '',
      conversationWithManager: null,
      managerName: '',
      managerEmail: '',
      managerPhone: '',
      managerHobby: '',
      isManagerMarried: false,
      note: ''
    }
  }),
  beforeMount() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      try {
        this.loading = true
        const {data} = await axios.get(`sk_visits/${this.visitId}/step/conversation`)
        const {conversation} = data
        this.conversation = {
          accountName: conversation.account_name,
          conversationWithManager: !!conversation.conversation_with_manager,
          managerName: conversation.manager_name,
          managerEmail: conversation.manager_email,
          managerPhone: conversation.manager_phone,
          managerHobby: conversation.manager_hobby,
          isManagerMarried: conversation.manager_married,
          note: conversation.note
        }
      } catch (e) {
        notification.notify(
          this.$t('notify.error'),
          this.$t('system.can_not_load'),
          'error');
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss">
.radio {
  label {
    margin-bottom: 0 !important;
  }
}
</style>
